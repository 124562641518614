import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { FaSoundcloud } from "react-icons/fa"
import styled from "styled-components"

export default function Mixtape() {
  return (
    <MixtapeWrapper>
      <h2>Mixtapes</h2>
      <p>
        Moonlighting as a DJ allows me to indulge my passion for music and
        dance. I've been spinning tunes for over a decade and have a deep
        appreciation for the art of mixing. Here's a recent mixtape I put
        together. Enjoy!
      </p>
      <div className="flex">
        <iframe
          title="SUMMER FLING mixtape"
          width="100%"
          height="166"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1932210245&color=%23808cc1&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
        ></iframe>
        <div
          style={{
            fontSize: "10px",
            color: "#cccccc",
            lineBreak: "anywhere",
            wordBreak: "normal",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontFamily:
              "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
            fontWeight: 100,
          }}
        >
          <a
            href="https://soundcloud.com/felix-the-cat-vibes"
            title="𝔪𝔢𝔬𝔴 * 𝔯𝔞𝔴𝔯 * 𝔭𝔲𝔯𝔯 *"
            target="_blank"
            style={{ color: "#cccccc", textDecoration: "none" }}
          >
            <FaSoundcloud style={{ marginRight: "4px" }} /> 𝔪𝔢𝔬𝔴 * 𝔯𝔞𝔴𝔯 * 𝔭𝔲𝔯𝔯 *
          </a>{" "}
          ·{" "}
          <a
            href="https://soundcloud.com/felix-the-cat-vibes/summer-fling"
            title="SUMMER FLING ~ 𝖕𝖚𝖗𝖗 𝖒𝖎𝖝𝖙𝖆𝖕𝖊 001"
            target="_blank"
            style={{ color: "#cccccc", textDecoration: "none" }}
          >
            SUMMER FLING ~ 𝖕𝖚𝖗𝖗 𝖒𝖎𝖝𝖙𝖆𝖕𝖊 001
          </a>
        </div>
      </div>
      {/* <div className="logo flex">
        <StaticImage
          src="../../static/logos/png/white_nobg.png"
          alt="Logo"
          placeholder="blurred"
          width={300}
          height={40}
          objectFit="fill"
        />
      </div> */}
    </MixtapeWrapper>
  )
}

const MixtapeWrapper = styled.section`
  padding: var(--gap) var(--borderSpacing) var(--sectionMargin);
  border-bottom: 2px var(--primary) solid;

  h2 {
    display: inline-block;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15);
  }

  .logo {
    margin: var(--gap) auto 0;
  }

  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    columns: 1;
    gap: var(--gap);
    margin-top: var(--gap);
  }
`
