import * as React from "react"
import { Link } from "gatsby"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../Button/Button"

const BannerModule = ({ children, title, subTitle }) => {
  return (
    <>
      <BannerModuleStyles>
        {children ? (
          children
        ) : (
          <StaticImage
            className="banner__image"
            imgClassName="banner__image--content"
            src="../../../static/hero.jpg"
            alt="Banner Image"
            layout="fullWidth"
            placeholder="blurred"
            objectPosition="50% 100%"
          />
        )}

        <div className="container">
          <div className="banner__content">
            {title ? (
              <h1>{title}</h1>
            ) : (
              <StaticImage
                className="logo__image"
                imgClassName="logo__image--content"
                src="../../../static/logos/png/white_nobg.png"
                alt="Logo Image"
                layout="fullWidth"
                placeholder="blurred"
              />
            )}
            {subTitle && <h2>{subTitle}</h2>}
            <div className="banner__btns">
              <Button text={"About Me"} as={Link} to={"/about"} />
            </div>
          </div>
        </div>
        <div className="gradient"></div>
      </BannerModuleStyles>
    </>
  )
}

export default BannerModule
